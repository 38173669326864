import type { FunctionComponent, MouseEvent, KeyboardEvent } from 'react';
import cx from 'classnames';

type ISkipLink = {
  targetID: string;
  text: string;
  className?: string;
};

const focusSkipLinkTarget = (link: HTMLAnchorElement) => {
  const href = link.getAttribute('href') || '';
  const target = document.getElementById(href.substr(1));

  if (target) {
    const oldTabIndex = target.getAttribute('tabindex') || '';

    target.setAttribute('tabindex', '0');
    target.focus();
    target.setAttribute('tabindex', oldTabIndex);
  }
};

const onSkipLinkClick = (e: MouseEvent) => {
  e.preventDefault();
  focusSkipLinkTarget(e.currentTarget as HTMLAnchorElement);
};

const onSkipLinkKeyPress = (e: KeyboardEvent) => {
  // eslint-disable-next-line default-case
  switch (e.key) {
    case 'ENTER':
    case ' ':
      e.preventDefault();
      focusSkipLinkTarget(e.currentTarget as HTMLAnchorElement);
  }
};

const SkipLink: FunctionComponent<ISkipLink> = ({ targetID, text, className }) => (
  <a
    href={`#${targetID}`}
    onClick={onSkipLinkClick}
    onKeyPress={onSkipLinkKeyPress}
    className={cx(className, 'btn-xl sr-only block text-xl leading-tight focus:not-sr-only')}
  >
    {text}
  </a>
);

export { SkipLink };

import { useTranslation } from 'next-i18next';

export type TColumns<T, K extends keyof T> = {
  key: K;
  header: string;
};

type TRows<T, K extends keyof T> = {
  data: Array<T>;
  columns: Array<TColumns<T, K>>;
  shouldShowRowHeader: boolean;
  country?: string;
};

type TTableHeader<T, K extends keyof T> = {
  columns: Array<TColumns<T, K>>;
  multiLevelHeaders?: Array<string>;
};

type TTable<T, K extends keyof T> = {
  data: Array<T>;
  columns: Array<TColumns<T, K>>;
  country?: string;
  shouldShowRowHeaders?: boolean;
  multiLevelHeaders?: Array<string>;
  caption?: string;
};

const TableHeader = <T, K extends keyof T>({ columns, multiLevelHeaders }: TTableHeader<T, K>) => {
  const headers = columns.map((column, index) => (
    <th
      key={`headCell-${column.header}`}
      className={index === 0 ? 'p-3 text-left' : 'p-3 text-center'}
      style={{ width: index !== 0 ? '6.25rem' : undefined }}
      scope="col"
    >
      {column.header}
    </th>
  ));

  const multiHeaders = multiLevelHeaders?.map((header) => <th key={header}>{header}</th>);

  return (
    <thead>
      {multiHeaders?.length ? <tr>{multiHeaders}</tr> : null}
      <tr className="bg-text-alt text-text-inverse">{headers}</tr>
    </thead>
  );
};

const TableRows = <T, K extends keyof T>({
  data,
  columns,
  country,
  shouldShowRowHeader,
}: TRows<T, K>) => {
  const { t } = useTranslation('events');
  const scope = shouldShowRowHeader ? 'row' : '';
  const rows = data.map((row, index) => (
    <tr
      key={`row-${row && typeof row === 'object' && 'name' in row ? (row.name as string) : ''}`}
      className={index % 2 === 0 ? 'bg-bg' : 'bg-bg-alt'}
    >
      {columns.map((column, index2) => {
        const cellValue = row[column.key] as string;
        const tdStyle = `p-3 ${index2 === 0 ? 'text-left' : 'text-center'}`;
        if (index2 === 0 && shouldShowRowHeader) {
          return (
            <th key={`cell-${column['header']}`} className={tdStyle} scope={scope}>
              {cellValue}
              <span className="text-text-alt block text-sm font-semibold italic">
                {country && country === 'US'
                  ? t('unitName.squareFeet')
                  : t('unitName.squareMeters')}
                : {row && typeof row === 'object' && 'size' in row ? (row.size as string) : '-'}
              </span>
            </th>
          );
        }

        return (
          <td key={`cell-${column['header']}`} className={tdStyle}>
            {cellValue || '-'}
          </td>
        );
      })}
    </tr>
  ));

  return <tbody>{rows}</tbody>;
};

const Table = <T, K extends keyof T>({
  data,
  columns,
  country,
  shouldShowRowHeaders = false,
  multiLevelHeaders = [],
  caption,
}: TTable<T, K>) => (
  <table className="col-span-6 text-xs sm:text-base md:col-span-12">
    {caption && <caption className="mb-4">{caption}</caption>}
    <TableHeader columns={columns} multiLevelHeaders={multiLevelHeaders} />
    <TableRows
      data={data}
      columns={columns}
      country={country}
      shouldShowRowHeader={shouldShowRowHeaders}
    />
  </table>
);

export default Table;

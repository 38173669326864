import type { FC } from 'react';
import cx from 'classnames';
import type { TSlides } from './HalfAndHalfCarousel-types';
import type { THalfAndHalfProps } from '../halfAndHalf/half-and-half-types';
import { HalfAndHalfContent } from '../halfAndHalf/half-and-half.content';

export const Slides: FC<TSlides> = function ({
  isDesktop,
  count,
  index,
  item,
  activeIndex,
  headline,
  isPositionOdd = true,
}) {
  const activeIndexDisplay = index + 1;
  const superHeadline = headline ? (
    <div className="mb-2 mt-4 text-base" aria-hidden="true">
      <span className="brand-ou:text-text-inverse mr-2 font-bold">{headline}</span>
    </div>
  ) : null;

  const showCta = Boolean(item?.link?.label && item?.link?.url);
  const ctaBtnObj = showCta
    ? {
        ctaText: item?.link?.label || '',
        ctaLink: item?.link?.url || '',
        isNewWindow: item?.link?.isNewWindow || false,
      }
    : undefined;

  const halfAndHalfProps: THalfAndHalfProps = {
    halfAndHalfImage: item?.image,
    index,
    headline: item?.name || '',
    headlineLevel: item?.headlineLevel,
    description: item?.desc || '',
    id: 'halfAndHalfCarousel',
    superHeadline,
    showCta,
    ctaBtnObj,
    count,
  };

  return (
    <div
      id="imageCarouselSlider"
      aria-label={`image-carousel${activeIndexDisplay}`}
      className={cx('container flex items-center flex-col xl:flex-row justify-center', {
        hidden: index !== activeIndex,
      })}
    >
      <div
        data-testid={`image-carousel-${activeIndexDisplay}`}
        className={cx('container flex justify-center', {
          'rounded-lg': false,
          'p-0': !!count,
        })}
      >
        <div className="w-full">
          <HalfAndHalfContent
            isPositionOdd={isPositionOdd}
            isDesktop={isDesktop}
            {...halfAndHalfProps}
          />
        </div>
      </div>
    </div>
  );
};

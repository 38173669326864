import { useRouter } from 'next/router';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { HotelPage, GLOBAL_NAMESPACES } from '../../../../helpers/constants';
import { getCtyhocnFromSlug, getHotelsRouteParams } from '../../../../helpers/routing';
import { getServerSideClients } from '@dx-ui/framework-react-query';
import { serverSideGetHotelEventsPageQuery } from '../../../../generated/react-query';
import { getLayoutData } from '../../../../helpers/layout';
import type { TVenuesCategory } from '../../../../components/event-capacity-chart/EventCapacityChart';
import { useTranslation } from 'next-i18next';
import {
  getDehydratedQueryState,
  serverSideLayoutAndRedirects,
} from '../../../../helpers/serverSideHelpers';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nextConfig from '../../../../next-i18next.config';
import HotelLayout from '../../../../components/layout/HotelLayout';
import EventCapacityChart from '../../../../components/event-capacity-chart/EventCapacityChart';
import EventSpaceFloorPlans from '../../../../components/event-space-floor-plans/event-space-floor-plans';
import HotelInfoDisplaySection from '../../../../components/property/hotel-info-display/HotelInfoDisplaySection';
import type { StandardSchema } from '../../../../components/head/page-schemas';
import { CustomHead } from '../../../../components/head';
import { GetBrandSvg } from '../../../../helpers/assets';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { getEventsCarouselImages } from '../../../../helpers/carousel';
import HalfAndHalfCarousel from '../../../../components/half-and-half-carousel/HalfAndHalfCarousel';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import type { HotelImage, HotelImageVariant } from '@dx-ui/gql-types';
import { useStaticUrls } from '../../../../hooks/useStaticUrls';
import { getDefaultBrandTheme } from '../../../../helpers/themes/defaultTheme';

const pageName = HotelPage.MEETINGSEVENTS;
const namespaces = [...GLOBAL_NAMESPACES, 'close-button', 'dx-image-carousel', pageName];

export default function Page({
  eventsData,
  showMeetingsSimplified,
  propCode,
  layout,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { t } = useTranslation(HotelPage.MEETINGSEVENTS);
  const router = useRouter();
  const { baseAppUrl } = useStaticUrls();
  const { ctyhocn } = getHotelsRouteParams(router);
  const categorySlugs = [
    'banquet',
    'conference',
    'hollowSquare',
    'reception',
    'classroom',
    'theater',
    'uShaped',
  ] as const;
  const venueCategoryNames = {
    banquet: t('banquet'),
    conference: t('conference'),
    hollowSquare: t('hollowSquare'),
    reception: t('reception'),
    classroom: t('classroom'),
    theater: t('theater'),
    uShaped: t('uShaped'),
  } as const;

  const venueCategoriesMap: {
    [name: string]: TVenuesCategory | null;
  } = {
    banquet: null,
    conference: null,
    hollowSquare: null,
    reception: null,
    classroom: null,
    theater: null,
    uShaped: null,
  };

  const country = eventsData?.address?.country || '';
  const showMetricUnits = country !== 'US';
  const meetingRooms = eventsData?.meetings?.meetingRooms;
  let hasVenues = false;

  if (meetingRooms?.length) {
    meetingRooms.forEach(
      ({ name_noTx: roomName, areaFeet, areaMeters, configurationCapacities }) => {
        categorySlugs.forEach((slug) => {
          const capacity = configurationCapacities && configurationCapacities[slug]?.toString();

          if (capacity) {
            let category = venueCategoriesMap[slug];

            if (!category) {
              category = {
                name: venueCategoryNames[slug],
                items: [],
              };

              venueCategoriesMap[slug] = category;
            }

            category.items.push({
              guests: capacity,
              name: roomName || '',
              size: showMetricUnits ? areaMeters || '' : areaFeet || '',
            });

            hasVenues = true;
          }
        });
      }
    );
  }

  const venueCategories: TVenuesCategory[] = [];

  if (hasVenues) {
    categorySlugs.forEach((slug) => {
      if (venueCategoriesMap[slug]) {
        venueCategories.push(venueCategoriesMap[slug]);
      }
    });
  }

  const mapImage = ({
    image,
  }: NonNullable<NonNullable<typeof eventsData>['images']>['meetingGallery'][number]) =>
    image && {
      altText: image.altText,
      variants:
        image.variants &&
        image.variants.filter((variant: HotelImageVariant) => variant && variant.url),
    };

  const hasVariants = (image?: Pick<HotelImage, 'altText' | 'variants'> | null) =>
    image?.variants.length;

  const carouselData = eventsData?.images?.meetingGallery.map(mapImage).filter(hasVariants);

  if (carouselData?.length === 0) {
    const defaultMeetingsImage = mapImage({ image: eventsData?.meetings?.image });

    if (hasVariants(defaultMeetingsImage)) {
      carouselData.push(defaultMeetingsImage);
    }
  }

  const desc = eventsData?.meetings?.desc || '';

  const standardSchema: StandardSchema = {
    address: layout?.address || {},
    coordinates: layout?.coordinates || undefined,
    image: layout?.image || '',
    openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
    schemaType: 'Hotel',
    description: layout?.seoMetaData?.desc,
    name: layout?.hotelName,
    url: baseAppUrl,
    telephone: layout?.phone || '',
  };

  return (
    <HotelLayout
      layout={layout}
      page={pageName}
      ctyhocn={ctyhocn}
      showNumAttendees={showMeetingsSimplified}
    >
      <CustomHead
        brandLogoImageSrc={GetBrandSvg(layout?.headerData.brandCode)}
        schema={standardSchema}
        metaDescription={layout?.seoMetaData.desc}
        metaKeywords={layout?.seoMetaData.keywords}
        page={pageName}
        pageTitle={layout?.seoMetaData.title}
        brandCode={layout?.headerData.brandCode}
        shouldUseDefaultBrandFavicon={layout?.headerData?.suppressLogo}
      />
      <div className="my-4 w-full lg:container">
        {eventsData?.images?.meetingGallery.length ? (
          <CarouselSingle
            className="lg:!aspect-[21/9] lg:rounded-lg"
            images={getEventsCarouselImages(eventsData.images.meetingGallery)}
          />
        ) : null}
      </div>
      <HotelInfoDisplaySection
        ctyhocn={ctyhocn}
        eventsData={eventsData}
        lang="en"
        showMeetingsSimplifiedTile={showMeetingsSimplified}
        textContent={{
          eventSpace: t('meetingDisplayItems.totalEventSpace'),
          guestRooms: t('meetingDisplayItems.guestRooms'),
          largestMeetingRoom: t('meetingDisplayItems.largestRoomSetup'),
          meetingRooms: t('meetingDisplayItems.meetingRooms'),
          title: t('header'),
          sqFt: t('unitName.squareFeetAbbv'),
          sqM: t('unitName.squareMetersAbbv'),
          subtitle: t('desc', { desc }),
        }}
      />
      {eventsData?.meetings?.places ? (
        <HalfAndHalfCarousel
          items={eventsData?.meetings?.places}
          headline={
            eventsData?.meetings?.places.length && eventsData?.meetings?.places.length > 1
              ? t('eventSpotlights')
              : t('eventSpotlight')
          }
        />
      ) : null}
      <EventSpaceFloorPlans propCode={propCode} />
      <EventCapacityChart
        country={eventsData?.address?.country || ''}
        venueCategories={venueCategories}
      />
    </HotelLayout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { res, params, locale } = context;
  const envVars = getQueryProviderProps();
  const ctyhocn = getCtyhocnFromSlug(params?.hotelSlug);
  const { queryClient } = getServerSideClients({
    customHeaders: { 'dx-trusted-app': 'true' },
    ...envVars,
    appName: 'dx-property-ui',
    response: res,
  });

  const {
    globalConfig,
    layoutResult,
    mainNavLinks,
    resEnabled,
    notFound,
    oneLinkProps,
    originalLocale,
    isBrandRefresh,
  } = await serverSideLayoutAndRedirects({
    queryClient,
    ctyhocn,
    pageName,
    hotelSlug: params?.hotelSlug as string,
    context,
  }).catch((e) => {
    console.log('Error in serverSideLayoutAndRedirects', e); // eslint-disable-line no-console

    return {
      globalConfig: null,
      layoutResult: null,
      mainNavLinks: null,
      resEnabled: null,
      notFound: true,
      oneLinkProps: null,
      originalLocale: null,
      featureToggles: null,
      isBrandRefresh: false,
    };
  });

  if (notFound) {
    return { notFound: true };
  }

  const layout = getLayoutData({
    layoutResult,
    resEnabled,
    mainNavLinks,
    locale: originalLocale || locale,
  });

  const brandCode = layoutResult?.hotelPageTemplate?.hotel?.brandCode || '';

  const eventsPageResult = await serverSideGetHotelEventsPageQuery(queryClient, {
    ctyhocn,
  }).catch((e) => {
    console.log('Error in serverSidePropertyPageQuery', e); // eslint-disable-line no-console

    return null;
  });

  if (!eventsPageResult) {
    return { notFound: true };
  }

  return {
    props: {
      eventsData: eventsPageResult?.hotel,
      globalConfig,
      isBrandRefresh,
      layout,
      propCode: layoutResult?.hotelPageTemplate?.hotel?.propCode,
      showMeetingsSimplified:
        layoutResult?.hotelPageTemplate?.hotel?.showMeetingsSimplified || false,
      themeColors: layout.theme ?? getDefaultBrandTheme(brandCode),
      ...(await serverSideTranslations('en', namespaces, nextI18nextConfig)),
      ...oneLinkProps,
      dehydratedQueryState: getDehydratedQueryState(queryClient),
      metrics: {
        trackingData: {
          pageData: {
            pageName,
            ctyhocn,
            destinationUrl: layout.homeUrlPath,
          },
        },
      },
    },
  };
}

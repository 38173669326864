import { isPropertyLocatedInUS } from '../../../helpers/property-utils';

import type { GetHotelEventsPageQuery } from '../../../generated/types';

export type THotelInfoDisplayText = {
  eventSpace: string;
  guestRooms: string;
  largestMeetingRoom: string;
  meetingRooms: string;
  title?: string;
  sqFt: string;
  sqM: string;
  subtitle?: string;
};

export type THotelInfoDisplay = {
  eventsData: GetHotelEventsPageQuery['hotel'];
  lang: string;
  textContent: THotelInfoDisplayText;
};

const formatNumber = (n: string | number | null | undefined, lang: string) =>
  n ? n.toLocaleString(lang) : '-';

const HotelInfoDisplay = ({ eventsData, textContent, lang }: THotelInfoDisplay) => {
  const meetingRooms = eventsData?.meetings?.numberOfMeetingRooms || 0;
  if (!meetingRooms) {
    return null;
  }

  const showMetricUnits = !isPropertyLocatedInUS(eventsData?.address);
  const totalArea = showMetricUnits
    ? eventsData?.meetings?.totalAreaMeters
    : eventsData?.meetings?.totalAreaFeet;
  const unitName = showMetricUnits ? textContent.sqM : textContent.sqFt;
  const largestMeetingRoom = showMetricUnits
    ? eventsData?.meetings?.largestMeetingRoomMeters
    : eventsData?.meetings?.largestMeetingRoomFeet;
  const guestRooms = formatNumber(eventsData?.totalRooms, lang);

  const displayItems = [
    {
      e2e: `hotelInfoDisplayTotalAreaItem`,
      displayItemName: textContent.eventSpace,
      number: totalArea,
      displayItemUnitName: unitName,
    },
  ];

  if (meetingRooms > 1) {
    displayItems.push({
      e2e: `hotelInfoDisplayLargestMeetingRoomItem`,
      displayItemName: textContent.largestMeetingRoom,
      number: largestMeetingRoom,
      displayItemUnitName: unitName,
    });
  }

  displayItems.push(
    {
      e2e: `hotelInfoDisplayMeetingRoomsItem`,
      displayItemName: textContent.meetingRooms,
      number: meetingRooms.toString(),
      displayItemUnitName: '',
    },
    {
      e2e: `hotelInfoDisplayGuestRoomsItem`,
      displayItemName: textContent.guestRooms,
      number: guestRooms,
      displayItemUnitName: '',
    }
  );

  return (
    <ul
      className="container flex flex-col justify-around gap-4 p-4 md:flex-row md:py-10"
      data-testid="hotelInfoDisplayHotelInfoDisplay"
      data-e2e="hotelInfoDisplayHotelInfoDisplay"
    >
      {displayItems?.map((hotelItemInfo) => (
        <li
          key={hotelItemInfo.displayItemName}
          data-testid={hotelItemInfo.e2e}
          className="col-span-3"
        >
          <span className="flex flex-col items-center">
            <span className="text-text order-1 text-xs font-bold uppercase md:text-sm">
              {hotelItemInfo?.displayItemName}
            </span>
            <div>
              <span className="text-primary text-3xl font-bold md:px-2.5 lg:text-5xl">
                {hotelItemInfo?.number}
              </span>
              {hotelItemInfo.displayItemUnitName && (
                <span className="text-text whitespace-nowrap text-base">
                  {hotelItemInfo.displayItemUnitName}
                </span>
              )}
            </div>
          </span>
        </li>
      ))}
    </ul>
  );
};

export default HotelInfoDisplay;

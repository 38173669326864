export type Breakpoint =
  | 'xxs' // 200
  | 'xs' // 320
  | 'sm' // 640
  | 'md' // 768
  | 'lg' // 1024
  | 'xl' // 1280
  | 'xxl' // 1440
  | 'xxxl' // 1920
  | 'xxxxl' // 2560
  | 'xxxxxl'; // 3840

export const breakpoints: Record<Breakpoint, number> = {
  xxs: 200,
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1920,
  xxxxl: 2560,
  xxxxxl: 3840,
};

import cx from 'classnames';
import { useRef } from 'react';
import { Cycle, CycleContext } from '@dx-ui/osc-cycle';
import { useRect } from '@dx-ui/utilities-use-rect';
import { Slides } from './Slides';
import { breakpoints } from '../responsive-image/breakpoints';
import { useGlobalConfig } from '../../hooks/use-global-config';
import type { THalfAndHalfCarouselProps } from './HalfAndHalfCarousel-types';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

const HalfAndHalfCarousel = ({
  items,
  headline,
  isPositionOdd,
  wrapperClass,
}: TWithCustomTheme<THalfAndHalfCarouselProps>) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const { isTailored } = useGlobalConfig();
  const isDesktop = (rect?.width ?? 0) > breakpoints['lg'];
  if (!items) {
    return null;
  }
  const itemsWithImages = items.filter((item) => !!item.image);

  const count = itemsWithImages.length;
  if (!count) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx(
        'py-8 lg:py-10',
        {
          'bg-bg-alt': !isTailored,
        },
        wrapperClass
      )}
      data-testid="halfAndHalf-carousel-content"
    >
      <Cycle total={count} current={0}>
        <CycleContext.Consumer>
          {({ active, cycleId }) => (
            <div id={cycleId}>
              <Slides
                activeIndex={active}
                index={active}
                item={itemsWithImages[active] || {}}
                count={count}
                headline={headline || ''}
                isDesktop={isDesktop}
                isPositionOdd={isPositionOdd}
              />
            </div>
          )}
        </CycleContext.Consumer>
      </Cycle>
    </div>
  );
};

export default HalfAndHalfCarousel;

import { useQuery } from '@tanstack/react-query';
import { ContextBasedSkipLink, SkipLinkProvider } from '../skip-link';
import { useTranslation } from 'next-i18next';
import { checkFor200Response } from '../../helpers/property-utils';
import { useRouter } from 'next/router';

type TEventSpaceFloorPlanProps = {
  propCode: string | undefined;
};

const CHART_HEADER_ID = 'chart-header';
const SUPPORTED_FLOOR_PLAN_LANGUAGES = {
  de: 'de',
  es: 'es',
  fi: 'fi',
  fr: 'fr',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  nl: 'nl',
  pt: 'pt',
  ru: 'ru',
  sv: 'sv',
  th: 'th',
  tr: 'tr',
  'zh-hans': 'zh-Hans',
  'zh-hant': 'zh-Hant',
};

const EventSpaceFloorPlans = ({ propCode }: TEventSpaceFloorPlanProps) => {
  const { t } = useTranslation('events');
  const router = useRouter();
  const locale = router.locale || 'en';
  const langParam =
    SUPPORTED_FLOOR_PLAN_LANGUAGES[locale as keyof typeof SUPPORTED_FLOOR_PLAN_LANGUAGES];
  const localizationParams = langParam ? `&lang=${langParam}&measurementSystem=METRIC` : '';
  const socialTablesUrl = `https://connect.socialtables.com/embedded-microsite?hotel_code=${propCode}${localizationParams}`;

  const { data: shouldDisplayIframe } = useQuery({
    queryKey: ['propCode', propCode, socialTablesUrl],
    queryFn: () => checkFor200Response(socialTablesUrl),
    retry: false,
    throwOnError: true,
  });

  const skipLink = (
    <SkipLinkProvider
      value={{
        target: CHART_HEADER_ID,
      }}
    >
      <ContextBasedSkipLink
        className="btn-primary-text float-right text-right underline"
        text={t('skipInteractiveFloorPlans')}
      />
    </SkipLinkProvider>
  );

  if (!shouldDisplayIframe) {
    return null;
  }

  return (
    <div className="container grid gap-4">
      <h2 className="heading-2xl lg:heading-3xl col-span-6 py-6 text-center md:col-span-12 md:pt-10 lg:pt-10">
        {t('eventSpaceFloorPlans')}
      </h2>
      {skipLink}
      <div className="border-bg-disabled relative top-2 col-span-6 clear-both w-full border-2 md:col-span-12">
        <iframe
          data-testid="social-table-iframe"
          src={socialTablesUrl}
          height="500px"
          allowFullScreen={true}
          title={t('floorPlansTitle')}
          className="w-full"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </div>
  );
};

export default EventSpaceFloorPlans;

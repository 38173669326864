import type * as React from 'react';
import { createElement, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import Markdown from 'markdown-to-jsx';
import { useCycle } from '@dx-ui/osc-cycle';
import { Pagination } from '@dx-ui/osc-pagination';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { THalfAndHalfContentProps } from './half-and-half-types';
import { Image } from '../image/Image';
import { getHotelImageVariant } from '../../helpers/property-utils';
import { BrandButton, BrandLink } from '../brand-buttons/brand-buttons';
import { Caption } from '@dx-ui/osc-caption';

function CarouselControls() {
  const { cycleId, active, total, onNextClick, onPreviousClick } = useCycle();
  return (
    <div
      data-e2e="arrows"
      className="pointer-events-none inset-0 justify-end whitespace-nowrap text-lg xl:flex-row"
      id={cycleId}
    >
      <Pagination
        controls={cycleId}
        label="Carousel"
        hideLabel
        current={active}
        total={total}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        loop
      />
    </div>
  );
}

function Headline({
  level,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement> & {
  level: NonNullable<THalfAndHalfContentProps['headlineLevel']>;
}) {
  const tagName = `h${level}` as const;
  return createElement(tagName, props, children);
}

const HalfAndHalfContent: React.FC<THalfAndHalfContentProps> = function ({
  index,
  headline,
  headlineLevel = 3,
  description,
  onOpen,
  showCta,
  showModalCta,
  modalCtaText,
  ctaBtnObj,
  halfAndHalfImage,
  isPositionOdd,
  isDesktop,
  superHeadline,
  bigNumberContent,
  awards,
  count,
}) {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const { t } = useTranslation('half-and-half');
  const imageAltText = halfAndHalfImage?.altText;
  const width = (rect?.width ?? 0) * (isDesktop ? 0.5 : 1);
  const imageUrl =
    halfAndHalfImage && Array.isArray(halfAndHalfImage.variants)
      ? getHotelImageVariant(halfAndHalfImage?.variants, width)?.url
      : null;
  const isCarousel = !!count;

  const HalfAndHalfImage = imageUrl ? (
    <div
      className={cx('relative flex overflow-hidden', {
        'flex-[1.3]': !isPositionOdd,
        'py-2': !isDesktop,
      })}
    >
      {halfAndHalfImage?.imageCaption ? <Caption caption={halfAndHalfImage?.imageCaption} /> : null}
      <Image key={index} alt={imageAltText || ''} src={imageUrl} width={width} aspectRatio="3:2" />
    </div>
  ) : null;

  return (
    <div
      className={cx('container flex justify-center', {
        'flex-row-reverse': !isPositionOdd,
        'py-8': !isCarousel,
        'p-0': isCarousel,
      })}
      ref={ref}
    >
      <div
        className={cx('flex flex-1 flex-col justify-between', {
          'ms-14': isDesktop && !isPositionOdd,
          'me-14': isDesktop && !!imageUrl,
          'w-full': !imageUrl || !isDesktop,
        })}
      >
        <div />
        <div className="justify-center md:mb-2 md:pt-2" data-testid="halfNHalfContentWrapper">
          {!isDesktop ? HalfAndHalfImage : null}
          {superHeadline || null}
          {headline ? (
            <Headline
              level={headlineLevel}
              className="heading-2xl lg:heading-3xl brand-ou:text-text-inverse lg:mb-2"
              data-testid="textBlockHeader"
            >
              {headline}
            </Headline>
          ) : null}
          {bigNumberContent || null}
          {description ? (
            <Markdown
              className="pt-4 text-base font-normal"
              options={{
                forceBlock: true,
                overrides: {
                  ul: {
                    props: {
                      className: 'list-disc ps-6',
                    },
                  },
                  ol: {
                    props: {
                      className: 'list-decimal ps-6',
                    },
                  },
                },
              }}
            >
              {description}
            </Markdown>
          ) : null}
          {awards || null}
          {showCta && ctaBtnObj?.ctaText ? (
            <div className="mt-8">
              <BrandLink
                url={ctaBtnObj?.ctaLink || ''}
                isNewWindow={!!ctaBtnObj?.isNewWindow}
                showNewWindowIcon={false}
                label={ctaBtnObj.ctaText}
              />
            </div>
          ) : null}
        </div>
        <div>
          {showModalCta && modalCtaText ? (
            <BrandButton
              label={modalCtaText}
              data-testid={`modalCtaText-${index}`}
              aria-label={modalCtaText || t('button')}
              onClick={onOpen}
              className="mt-2"
            />
          ) : null}
        </div>
        {(count ?? 0) > 1 && (
          <div
            className={cx('mt-8', {
              'lg:self-start': isPositionOdd,
              'lg:self-end': !isPositionOdd,
            })}
          >
            <CarouselControls />
          </div>
        )}
      </div>
      {isDesktop ? HalfAndHalfImage : null}
    </div>
  );
};

export { HalfAndHalfContent };

import { useTranslation } from 'next-i18next';
import type { TColumns } from '../property/table/Table';
import Table from '../property/table/Table';

type TVenuesItem = {
  guests: string;
  name: string;
  size: string;
  imageAlt?: string;
  imageSrc?: string;
};

export type TVenuesCategory = {
  name: string;
  items: TVenuesItem[];
};

type TEventCapacityChart = {
  country: string;
  venueCategories?: TVenuesCategory[];
};

type TTableItem = TVenuesItem & {
  [key: string]: string;
};
const EventCapacityChart = ({ country, venueCategories }: TEventCapacityChart) => {
  const { t } = useTranslation('events');
  const chartHeaderId = 'chart-header';

  let tableData: TTableItem[] = [];
  const columns: TColumns<TTableItem, keyof TTableItem>[] = [
    {
      key: 'name',
      header: t('eventRoomName'),
    },
  ];

  if (Array.isArray(venueCategories)) {
    // venueCategories is an optional prop, so we shouldn't throw if undefined
    venueCategories.forEach((category) => {
      if (!category.name || !Array.isArray(category.items)) {
        return; // We need valid data to populate the columns
      }
      columns.push({
        key: category.name,
        header: category.name,
      });

      category.items.forEach((item) => {
        const itemExists = tableData.filter((data) => data.name === item.name).length > 0;

        const tableItem: TTableItem = {
          ...item,
        };

        tableItem[category.name] = item.guests;

        if (itemExists) {
          tableData = tableData.map((data) => {
            if (data.name === item.name) {
              const tItem: TTableItem = {
                ...data,
              };
              tItem[category.name] = item.guests;

              return tItem;
            }

            return data;
          });
        } else {
          tableData.push(tableItem);
        }
      });
    });
  }

  return (
    <>
      <div className="mt-6 flex flex-row justify-center text-2xl font-bold md:mt-8">
        <h3 className="heading-2xl lg:heading-3xl" id={chartHeaderId} tabIndex={-1}>
          {t('capacityChart')}
        </h3>
      </div>
      <p className="mt-4 text-center text-base">{t('chartDescription')}</p>
      <div className="container mt-6 grid grid-cols-6 gap-4 overflow-x-scroll md:grid-cols-12">
        <Table data={tableData} columns={columns} country={country} shouldShowRowHeaders />
      </div>
    </>
  );
};

export default EventCapacityChart;

import { createContext, useContext } from 'react';

import { SkipLink } from './skip-link';

type IContextBasedSkipLinkContext = {
  target: string;
};

type IContextBasedSkipLinkProps = {
  text?: string;
  className?: string;
};

const skipLinkContext = createContext<IContextBasedSkipLinkContext>({
  target: '',
});

export const SkipLinkProvider = skipLinkContext.Provider;

const ContextBasedSkipLink = ({ text = 'Skip', className }: IContextBasedSkipLinkProps) => {
  const { target } = useContext(skipLinkContext);
  return <SkipLink className={className} targetID={target} text={text} />;
};

export { ContextBasedSkipLink };

import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import HotelInfoDisplay from './HotelInfoDisplay';
import type { THotelInfoDisplay } from './HotelInfoDisplay';
import type { InformationTileProps } from '../../information-tile';
import { InformationStrip } from '../../information-strip';
import { config } from '../../../helpers/constants';
import { baseUrl } from '../../../helpers/env-vars';
import Icon from '@dx-ui/osc-icon';

type THotelInfoDisplaySectionProps = {
  ctyhocn: string;
  showMeetingsSimplifiedTile: boolean;
} & THotelInfoDisplay;

const HotelInfoDisplaySection = ({
  ctyhocn,
  eventsData,
  lang,
  showMeetingsSimplifiedTile,
  textContent,
}: THotelInfoDisplaySectionProps) => {
  const { t } = useTranslation('events');

  const { rfpUrl, hiltonLinkUrl, numberOfMeetingRooms } = eventsData?.meetings || {};

  const informationTiles = (): InformationTileProps[] => {
    const tiles: InformationTileProps[] = [];
    const brandCodeValue = eventsData?.brandCode || config.DEFAULT_BRAND_CODE;
    const roomBlockUrl = `${baseUrl}/en/group-search/?query=${ctyhocn}&brandCode=${brandCodeValue}&numRooms=10`;

    tiles.push({
      children: t('reserveHotelRooms.text'),
      ctaType: 'link',
      icon: <Icon name="group" variant="solid" />,
      title: t('reserveHotelRooms.title'),
      label: t('reserveHotelRooms.label'),
      url: roomBlockUrl,
    });

    if (rfpUrl) {
      tiles.push({
        children: t('rfp.text'),
        ctaType: 'link',
        icon: <Icon name="cocktails" variant="solid" />,
        title: t('rfp.title'),
        label: t('rfp.label'),
        url: rfpUrl || '',
      });
    }

    if (showMeetingsSimplifiedTile) {
      const meetingsSimplifiedUrl = `${baseUrl}/en/group-search/?query=${ctyhocn}&brandCode=${brandCodeValue}&numRooms=0&attendee_count=10`;
      tiles.push({
        children: t('meetingsSimplified.text'),
        ctaType: 'link',
        icon: <Icon name="meeting" variant="solid" />,
        title: t('meetingsSimplified.title'),
        label: t('meetingsSimplified.label'),
        url: meetingsSimplifiedUrl,
      });
    }

    tiles.push({
      children: t('attendeeWebsite.text'),
      ctaType: 'link',
      icon: <Icon name="calendar" variant="solid" />,
      title: t('attendeeWebsite.title'),
      label: t('attendeeWebsite.label'),
      url: hiltonLinkUrl || 'WW',
    });

    return tiles;
  };

  if (!numberOfMeetingRooms) {
    return null;
  }

  return (
    <div className="bg-bg-alt" data-testid="HotelInfoDisplaySection">
      <div className="container grid justify-items-center gap-4 px-4 md:grid-cols-12">
        {textContent.title ? (
          <div className="col-span-6 justify-items-center md:col-span-12">
            <h1 className="heading-3xl lg:heading-4xl mb-0 mt-4 md:mt-12 lg:mt-6">
              {textContent.title}
            </h1>
          </div>
        ) : null}
        {textContent.subtitle ? (
          <div className="col-span-6 md:col-span-8 md:col-start-3">
            <p className="text-text col-span-6 mb-0 text-center text-base  lg:mt-4">
              {textContent.subtitle}
            </p>
          </div>
        ) : null}
      </div>
      <HotelInfoDisplay eventsData={eventsData} textContent={textContent} lang={lang} />
      <div className="container grid grid-cols-6 justify-items-center gap-4 px-0 md:grid-cols-12">
        <div className="container col-span-6 md:col-span-12">
          <InformationStrip tiles={informationTiles()} />
        </div>
      </div>
    </div>
  );
};

export default memo(HotelInfoDisplaySection);
